import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import TopBar from "./components/TopBar";
import Body from "./components/Body";
import Main from "./components/Main";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path="" element={<Main />} />
          <Route
            path="app"
            element={
              <div className="App">
                <TopBar />
                <Body />
              </div>
            }
          />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
