import {
  ContractId,
  ContractExecuteTransaction,
  ContractFunctionParameters,
  Hbar,
  PrivateKey,
} from "@hashgraph/sdk";
import { HashConnect } from "hashconnect";

let hashconnect = new HashConnect();

let appMetadata = {
  name: "Lend",
  description: "A lend interface for Hedera",
  icon: "https://cryptologos.cc/logos/hedera-hbar-logo.png",
};

let contractId = ContractId.fromString("0.0.47907889");

export async function connect({ setWalletInfo }) {
  let saveData = {
    topic: "",
    pairingString: "",
    privateKey: "",
    pairedWalletData: null,
    pairedAccounts: [],
  };
  let accountId = "";

  //first init and store the private for later
  let initData = await hashconnect.init(appMetadata);
  saveData.privateKey = initData.privKey;

  //then connect, storing the new topic for later
  let state = await hashconnect.connect();
  saveData.topic = state.topic;

  console.log("\nTopic is: $(saveData.topic)\n");

  //generate a pairing string, which you can display and generate a QR code from
  saveData.pairingString = hashconnect.generatePairingString(
    state,
    "testnet",
    false
  );

  //find any supported local wallets
  const resultWallet = hashconnect.findLocalWallets();
  //    provider = hashconnect.getProvider(network, topic, accountId);
  //    let balance = await provider.getAccountBalance(accountId);
  //    signer = hashconnect.getSigner(provider);

  console.log(state);

  console.log(resultWallet + "result");
  hashconnect.connectToLocalWallet(saveData.pairingString);

  hashconnect.pairingEvent.once((pairingData) => {
    pairingData.accountIds.forEach((id) => {
      accountId = id;
      setWalletInfo({ saveData, accountId });
    });
  });
}

export async function run({ walletInfo }) {
  const provider = hashconnect.getProvider(
    "testnet",
    walletInfo.saveData.topic,
    walletInfo.accountId
  );
  const signer = hashconnect.getSigner(provider);
  const stakeTx = await new ContractExecuteTransaction()
    .setContractId(contractId)
    .setGas(500000)
    .setPayableAmount(50)
    .setFunction("stakeTokens")
    .freezeWithSigner(signer);

  const result = await stakeTx.executeWithSigner(signer);
  console.log(result);
}

export async function runDeposit({ walletInfo, stakeAmount }) {
  const provider = hashconnect.getProvider(
    "testnet",
    walletInfo.saveData.topic,
    walletInfo.accountId
  );
  const signer = hashconnect.getSigner(provider);
  const depositTx = await new ContractExecuteTransaction()
    .setContractId(ContractId.fromString("0.0.48769482"))
    .setGas(1000000)
    .setPayableAmount(new Hbar(stakeAmount))
    .setFunction(
      "deposit",
      new ContractFunctionParameters().addUint64(100000000 * stakeAmount) //TODO: must have exchange rate
    )
    .freezeWithSigner(signer);

  const res = await depositTx.executeWithSigner(signer);
  console.log(res);
}

export async function getAccountId({ walletInfo }) {
  return walletInfo.accountId;
}

export async function getAccountBalance({ walletInfo }) {
  const provider = hashconnect.getProvider(
    "testnet",
    walletInfo.saveData.topic,
    walletInfo.accountId
  );
  let balance = await provider.getAccountBalance(walletInfo.accountId);

  return balance;
}
