import CustomText from "./CustomText";

export default function CustomButton({ disabled, onClick, style, children }) {
  return (
    <div
      style={{
        borderRadius: "0.5em",
        backgroundColor: "#000",
        padding: "0.5em 1em",
        cursor: "pointer",
        ...style,
      }}
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      <CustomText
        size={12}
        weight={"bold"}
        style={{ color: "#FFF", margin: "0", textAlign: "center" }}
      >
        {children}
      </CustomText>
    </div>
  );
}
