import { useState } from "react";

import CardSVG from "../assets/Card.svg";
import BankSVG from "../assets/Bank.svg";

import "../App.css";
import CategoryPicker from "./CategoryPicker";
import LenderComponent from "./LenderComponent";
import StakerComponent from "./StakerComponent";

function Body() {
  const [category, setCategory] = useState("stake");

  return (
    <div
      style={{
        backgroundColor: "#FFFFFa",
        height: "92vh",
        padding: "2em",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CategoryPicker category={category} setCategory={setCategory} />
      {category === "stake" && <StakerComponent />}
      {category === "lend" && <LenderComponent />}
      {category === "borrow" && <LenderComponent />}
    </div>
  );
}

export default Body;
