import { useNavigate } from "react-router-dom";

function TopBar() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        backgroundColor: "#FFFFEE",
        borderBottom: "1px solid #cdcdcd",
        height: "8vh",
        padding: "0.5em 3em",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => navigate("/")}
      >
        {/* <div
          style={{
            backgroundColor: "red",
            height: "1.5em",
            width: "1.5em",
            marginRight: "1em",
            borderRadius: "0.4em",
          }}
        ></div> */}
        <p style={{ fontSize: "20px", margin: "0em", color: "#646464" }}>
          Hashgraph Finance
        </p>
      </div>
    </div>
  );
}

export default TopBar;
