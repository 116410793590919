import CardSVG from "../assets/Card.svg";
import BankSVG from "../assets/Bank.svg";
import LockSVG from "../assets/Lock.svg";

import "../App.css";

function CategoryPicker({ category, setCategory }) {
  return (
    <div
      style={{
        backgroundColor: "#FFFFEE",
        height: "3em",
        width: "50%",
        border: "1px solid #cdcdcd",
        borderRadius: "0.5em",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
      className={"CategoryPicker"}
    >
      <div
        className={
          category === "stake"
            ? "CategoryButtonSelected CategoryButton"
            : "CategoryButton"
        }
        onClick={() => setCategory("stake")}
      >
        <img
          src={LockSVG}
          style={{ height: "1em", width: "1em", marginRight: "0.5em" }}
          alt="LockSVG"
        />

        <p style={{ fontSize: "14px", margin: "0em", color: "#646464" }}>
          Stake
        </p>
      </div>
      <div
        className={
          category === "lend"
            ? "CategoryButtonSelected CategoryButton"
            : "CategoryButton"
        }
        onClick={() => setCategory("lend")}
      >
        <img
          src={BankSVG}
          style={{ height: "1em", width: "1em", marginRight: "0.5em" }}
          alt="BankSVG"
        />

        <p style={{ fontSize: "14px", margin: "0em", color: "#646464" }}>
          Lend
        </p>
      </div>
      <div
        className={
          category === "borrow"
            ? "CategoryButtonSelected CategoryButton"
            : "CategoryButton"
        }
        onClick={() => setCategory("borrow")}
      >
        <img
          src={CardSVG}
          style={{ height: "1em", width: "1em", marginRight: "0.5em" }}
          alt="CardSVG"
        />
        <p style={{ fontSize: "14px", margin: "0em", color: "#646464" }}>
          Borrow
        </p>
      </div>
    </div>
  );
}

export default CategoryPicker;
