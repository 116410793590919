export default function CustomText({
  size,
  weight,
  style,
  inline,
  center,
  bold,
  children,
  props,
}) {
  return inline ? (
    <span
      style={{
        fontFamily: "Open Sans",
        fontSize: size,
        fontWeight: bold ? "bold" : weight,
        margin: 0,
        textAlign: center ? "center" : "left",
        ...style,
      }}
    >
      {children}
    </span>
  ) : (
    <p
      style={{
        fontFamily: "Open Sans",
        fontSize: size,
        fontWeight: bold ? "bold" : weight,
        margin: 0,
        textAlign: center ? "center" : "left",

        ...style,
      }}
    >
      {children}
    </p>
  );
}
