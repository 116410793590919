import { TextField } from "@mui/material";
import { useState } from "react";

import "../App.css";

import ShoppingCartSVG from "../assets/ShoppingCart.svg";

function LenderComponent() {
  return (
    <div className="ConnectWalletSection">
      <img
        src={ShoppingCartSVG}
        style={{ height: "2em", width: "2em", marginRight: "1em" }}
        alt="ShoppingCartSVG"
      />
      <p style={{ fontSize: "16px", margin: "0em", color: "#646464" }}>
        Coming soon...
      </p>
    </div>
  );
}

export default LenderComponent;
