import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";
import { Blob } from "react-interactive-blob";

function Main() {
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          backgroundColor: "rgba(255, 255, 119, 0.5)",
          // height: "4vh",
          width: "100vw",
          position: "absolute",
          padding: "1em",
          zIndex: 1,
        }}
        onClick={() => navigate("/app")}
      >
        <p className="AnnouncementBarText">
          Announcement: Staking is now live on Hedera Testnet!
        </p>
      </div>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          position: "absolute",
          // backgroundColor: "red",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // zIndex: "-1",
        }}
      >
        <Blob
          height={800}
          radius={250}
          markers={false}
          sensitivity={0.7}
          // speed={1}
          color="rgba(255, 255, 119, 0.5)"
        />
      </div>
      <div
        style={{
          backgroundColor: "#FFFFF5",
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ zIndex: 2 }}>
          <p style={{ fontSize: "36px", margin: "0em", color: "#154ca3" }}>
            <Typewriter
              options={{
                strings: ["Lend", "Borrow", "Stake"],
                autoStart: true,
                loop: true,
                delay: 30,
                deleteSpeed: 20,
              }}
            />
          </p>
          <p
            style={{
              fontSize: "36px",
              marginBlock: "0.5em 0em",
              color: "#646464",
            }}
          >
            Natively on Hedera
          </p>
          <p
            style={{
              fontSize: "16px",
              marginBlock: "1.5em",
              color: "#646464",
              fontStyle: "italic",
            }}
          >
            all on Hashgraph Finance
          </p>
          <div style={{ cursor: "pointer" }} onClick={() => navigate("/app")}>
            <p className="MainSubText">Go to app →</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;
