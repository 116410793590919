import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { HashConnect } from "hashconnect";
import { connect, run, runDeposit, getAccountBalance } from "./HederaUtils";

import "../App.css";

import CustomButton from "./CustomButton";
import CustomText from "./CustomText";
import HederaLogoSVG from "../assets/HederaLogo.png";
import Connected from "./Connected";
import InformationSVG from "../assets/Information.svg";
import CheckSVG from "../assets/Check.svg";
import Deposit from "./Deposit";

function StakerComponent() {
  const [stakeAmount, setStakeAmount] = useState("");
  const [balance, setBalance] = useState();

  const [walletInfo, setWalletInfo] = useState();

  useEffect(() => {
    if (!!walletInfo) {
      getAccountBalance({ walletInfo }).then((res) => {
        const hbarBalance = res.toJSON()["hbars"];
        const stakingTokenInWallet = res
          .toJSON()
          .tokens.filter((token) => token.tokenId === "0.0.34832666");
        const tokenExistsInWallet = stakingTokenInWallet.length > 0;
        if (tokenExistsInWallet) {
          setBalance({
            hbars: hbarBalance,
            stakingToken: stakingTokenInWallet[0].balance,
          });
        } else {
          setBalance({
            hbars: hbarBalance,
            stakingToken: 0,
          });
        }
      });
    }
  }, [walletInfo]);

  return (
    <div className="StakingSection">
      <p style={{ fontSize: "12px", color: "#646464" }}>
        My HBAR: {!!walletInfo ? balance?.hbars : "-"}
      </p>
      <p style={{ fontSize: "12px", margin: "1em 0em", color: "#646464" }}>
        My sHBAR: {!!walletInfo ? balance?.stakingToken : "-"}
      </p>

      <TextField
        value={stakeAmount}
        onChange={(e) => {
          let input = e.target.value;
          if (!input || /^-?\d+$/.test(input)) setStakeAmount(input);
        }}
        InputProps={{
          startAdornment: (
            <img
              src={HederaLogoSVG}
              style={{ height: "1em", width: "1em", marginRight: "0.5em" }}
              alt="HederaLogoSVG"
            />
          ),
          // classes: { "&::placeholder": { color: "red", fontSize: "50px" } },
        }}
        placeholder={"Enter HBAR amount"}
        style={{
          width: "100%",
          // padding: "0em 2.5%",
          backgroundColor: "#FFFFFa",
        }}
      />
      <p
        style={{
          fontSize: "16px",
          fontWeight: "400",
          margin: "1em 0em 1em 0em",
          color: "#646464",
        }}
      >
        You will receive: {stakeAmount ? stakeAmount : 0} sHBAR
      </p>
      <p style={{ fontSize: "12px", margin: "1em 0em", color: "#646464" }}>
        Transaction fee: ~3.011 HBAR
      </p>
      {!walletInfo ? (
        <>
          {" "}
          <div
            style={{
              backgroundColor: "#e0f1ff",
              margin: "1em 0em",
              padding: "0.5em",
              borderRadius: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={InformationSVG}
              style={{ height: "1em", width: "1em", marginRight: "0.5em" }}
              alt="InformationSVG"
            />
            <CustomText
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#555555",
                textAlign: "center",
              }}
            >
              Connect your wallet to stake
            </CustomText>
          </div>
          <CustomButton
            style={{
              borderRadius: "6px",
              height: "2em",
              backgroundColor: "#414141",
              // margin: "1em 2.5% 0em 2.5%",
              // width: "80%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // maxWidth: "90%",
              // padding: "0em 2.5%",
            }}
            // onClick={() => connectWallet()}
            onClick={() => connect({ setWalletInfo })}
            // disabled={loading}
          >
            <CustomText
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#FFF",
                textAlign: "center",
              }}
            >
              Connect Wallet
            </CustomText>
          </CustomButton>
        </>
      ) : (
        <>
          <Deposit stakeAmount={stakeAmount} walletInfo={walletInfo} />
        </>
      )}
    </div>
  );
}

export default StakerComponent;
