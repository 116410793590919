import "../App.css";
import { HashConnect } from "hashconnect";
import { hethers } from "@hashgraph/hethers";
import {
  ContractExecuteTransaction,
  ContractFunctionParameters,
  Hbar,
  TransactionReceipt,
} from "@hashgraph/sdk";

import { useState, useEffect } from "react";

import CustomButton from "./CustomButton";
import CustomText from "./CustomText";
import CheckSVG from "../assets/Check.svg";
import { getAccountId, runDeposit } from "./HederaUtils";

function Deposit({ stakeAmount, walletInfo }) {
  return (
    <>
      <div
        style={{
          backgroundColor: "#e0ffe8",
          margin: "1em 0em",
          padding: "0.5em",
          borderRadius: "6px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={CheckSVG}
          style={{ height: "1em", width: "1em", marginRight: "0.5em" }}
          alt="CheckSVG"
        />
        <CustomText
          style={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#555555",
            textAlign: "center",
          }}
        >
          Wallet {walletInfo.accountId} Connected
        </CustomText>
      </div>

      <CustomButton
        style={{
          borderRadius: "6px",
          height: "2em",
          backgroundColor: "#414141",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => runDeposit({ walletInfo, stakeAmount })}
        disabled={!stakeAmount}
      >
        <CustomText
          style={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#FFF",
            textAlign: "center",
          }}
        >
          Stake {stakeAmount ? stakeAmount : 0} HBAR
        </CustomText>
      </CustomButton>
      <div style={{ margin: "1em 0em 0em", cursor: "pointer" }}>
        <CustomText
          style={{
            fontSize: "12px",
            fontWeight: "400",
            color: "#555555",
            textAlign: "center",
          }}
        >
          Want to unstake?
        </CustomText>
      </div>
    </>
  );
}

export default Deposit;
